<template>
  <b-img
    v-bind="$attrs"
    :src="computedSrc"
    :alt="alt"
    :style="isPlaceholder ? placeholderClass : ''"
    @error="handleLoadingError"
  />
</template>

<script>
import { BImg } from 'bootstrap-vue';

export default {
  name: 'SafeImg',
  components: { BImg },
  props: {
    src: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      default: '',
    },
    placeholderClass: {
      type: String,
      default: '',
    },
    retry: {
      type: [Boolean, Number],
      default: false,
    },
  },
  data() {
    return {
      hasFailed: false,
      retryCount: 0,
    };
  },
  computed: {
    computedSrc() {
      return this.isPlaceholder ? this.placeholder : this.src || this.placeholder;
    },
    isPlaceholder() {
      return !this.src || this.hasFailed;
    },
  },
  watch: {
    src() {
      this.hasFailed = false;
      this.fireSrcChanged();
    },
  },
  mounted() {
    this.fireSrcChanged();
  },
  methods: {
    handleLoadingError() {
      this.hasFailed = true;
      this.fireSrcChanged();
      if (this.retry && this.retryCount === 0) {
        setTimeout(() => { this.retryImageLoad(); }, this.retry === true ? 2000 : this.retry);
      }
    },
    fireSrcChanged() {
      this.$emit('src-changed', {
        src: this.computedSrc,
        isPlaceholder: this.isPlaceholder,
      });
    },
    retryImageLoad() {
      this.retryCount += 1;
      this.hasFailed = false;
      this.fireSrcChanged();
    },
  },
};
</script>
