<template>
  <list-container-widget
    :id="`entity-${key}`"
    :title="key === 'noKey' ? widgetName : translatedType"
    :app-id="57"
    :type="key === 'noKey' ? null : key"
    stretch
  >
    <template #actions>
      <!-- CUIDADO!!!!! NO BORRAR  -->
      <div ref="target" />
      <!-- <div ref="target" style="width: 5px; height: 5px; background-color: black;" /> -->

      <!-- Panel actions -->
      <!-- <widget-actions
        item-type="entity"
        :show-list="true"
        :show-filter="false"
        :show-add="canAdd || isStaff"
        :type="key"
        :type-name="key"
        @updateSearch="searchItems"
      /> -->
      <ViewAllButton item-type="entity" :type="key" />
    </template>
    <div v-if="results != false">
      <base-horizontal-container
        #default="{ item }"
        :item-name="translatedType"
        :items="results"
        item-type="entities"
        :is-draggable="false"
        :total="total"
        :loading="loading"
        :per-page="perPage"
        :placeholder="placeholder"
        :placeholder-message="$t('entities.placeholder-text')"
        :loading-next="isLoadingNextPage"
        scroll-container-class="d-flex align-items-stretch"
        scrollable
      >
        <entities-card
          :entity="item"
        />
      </base-horizontal-container>
    </div>

    <base-horizontal-container
      v-else
      #default="{ item }"
      :item-name="translatedType"
      :items="entities"
      item-type="entities"
      :is-draggable="false"
      :total="total"
      :loading="loading"
      :per-page="perPage"
      :placeholder="placeholder"
      :placeholder-message="$t('entities.placeholder-text')"
      :loading-next="isLoadingNextPage"
      scroll-container-class="d-flex align-items-stretch"
      scrollable
    >
      <entities-card
        :entity="item"
        :type="item.type"
        @removed="handleRemoved($event)"
      />
    </base-horizontal-container>

    <description-addon v-model="isAppInfoModalVisible" :app-id="62" />
  
  </list-container-widget>
</template>

<script>
import WidgetLayoutMixin from '@/@core/mixins/widgets/WidgetLayoutMixin';
import MembersPlaceholder from '@/assets/images/placeholders/light/entities.svg';
import BaseHorizontalContainer from '@core/components/containers/base/BaseHorizontalContainer.vue';
import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';
import { translateTranslationTable } from '@core/libs/i18n/utils';
import {
  ENTITIES_STORE_MODULE_NAME,
  ENTITIES_GETTERS,
} from '@/views/apps/entities/constants/entities-store-constants';
import {
  ENABLED_APPS_GETTERS,
} from '@/store/enabled-apps/enabled-apps-store-constants';

import entitiesStoreModule from '@/views/apps/entities/store/entitiesStoreModule';
import EntitiesCard from '@/views/apps/entities/components/EntitiesCard.vue';
import DescriptionAddon from '@/@core/widgets/DescriptionAddon.vue';
// import WidgetActions from '@/@core/components/widget-actions/WidgetActions.vue';
// import AjaxSelect from '@core/components/ajax-select/AjaxSelect.vue';
import OrganizationsAPI from '@/views/apps/organization/store/OrganizationsAPI';
// import vSelect from 'vue-select';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
// import FileTypes from '@/@core/constants/FileTypes';
// import { quillEditor } from 'vue-quill-editor';
import { checkPermissions } from '@/@core/utils/roles-utils';
import MembersSelect from '@/views/apps/member/components/MembersSelect.vue';

import { ref } from '@vue/composition-api';
import { useElementVisibility } from '@vueuse/core'

export default {
  name: 'EntitiesListWidget',

  setup() {
    const target = ref(null)
    const targetIsVisible = useElementVisibility(target)

    return {
      target,
      targetIsVisible,
    }
  },

  components: {
    BaseHorizontalContainer,
    ListContainerWidget,
    EntitiesCard,
    DescriptionAddon,
    // quillEditor,
    // vSelect,
    // AjaxSelect,
    // WidgetActions,
    MembersSelect,
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
  },
  mixins: [WidgetLayoutMixin],
  props: {
    type: {
      type: Object,
      required: true,
    },
    perPage: {
      type: Number,
      default: 8,
    },
    communityKey: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      currentPage: 0,
      loading: true,
      formInput: {},
      isLoadingNextPage: false,
      formError: null,
      itemImage: null,
      imageSrc: null,
      isAppInfoModalVisible: false,
      entitiesData: {},
      results: [],
      resultItem: [],
      selectedType: null,
    };
  },
  computed: {
    key() {
      return this.type?.key;
    },
    entities() {
      if (this.$store.getters.entitiesByType[this.key] != null) {
        this.entitiesData = this.$store.getters.entitiesByType[this.key].unpaginated;

        return this.$store.getters.entitiesByType[this.key].unpaginated;
      }
      return [];
    },
    isStaff() {
      return this.collective.isStaff;
    },
    isEditable() {
      return this.$store.getters[ENABLED_APPS_GETTERS.isEditingLayout];
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canAdd() {
      return checkPermissions(
        'create',
        'entity',
        this.loggedMemberRoles,
        this.collective,
      );
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    FileTypes() {
      return FileTypes;
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
    total() {
      return this.$store.getters[ENTITIES_GETTERS.getTotal]({
        typeKey: this.key,
      });
    },
    translatedType() {
      const name = translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.type.namePlural,
      );
      if (name != 'Entities') {
        return name;
      }
      return this.$t('entities.pageTitle');
    },
    placeholder() {
      return MembersPlaceholder;
    },
    types() {
      if (this.$store.getters.types.AllEntitiesTypes) {
        const typesSelect = [];
        const uniqueChars = this.$store.getters.types.AllEntitiesTypes.unpaginated.filter((item, index, allTypes) => index === allTypes.findIndex((type) => (
          type.key === item.key
        )));
        uniqueChars.map((item) => typesSelect.push({
          name: this.translate(item.name),
          key: item.key,
        }));
        return typesSelect;
      } return [];
    },
  },

  watch: {
    targetIsVisible (newValue) {
      // console.log("++++++++++++ --> WATCH!!! EntitiesListWidget.vue (targetIsVisible): ", newValue)
      if (newValue && !this.entities.length) {
        // console.log("2 --> WATCH!!! EntitiesListWidget.vue (targetIsVisible): ", newValue)
        this.fetchEntities();
      }
    }
  },

  beforeCreate() {
    if (!this.$store.hasModule(ENTITIES_STORE_MODULE_NAME)) {
      this.$store.registerModule(
        ENTITIES_STORE_MODULE_NAME,
        entitiesStoreModule,
      );
    }
  },

  // async created() {
  //   this.loading = true;
  //   await this.fetchEntities();
  //   this.loading = false;
  // },

  methods: {
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },
    async fetchEntities(force = true) {
      this.loading = true;
      await this.$store.dispatch('getItems', {
        itemType: 'entities',
        customName: 'entitiesByType',
        storedKey: this.key,
        forceAPICall: force,
        page: 1,
        requestConfig: {
          count: 100,
          orderByDate: -1,
          count: 100,
          typeKey: this.key,
        },
        communityKey: this.communityKey,
      });
      this.loading = false;
    },
    async fetchData(page, searchString = '') {
      return await OrganizationsAPI.fetchPage({
        communityKey: this.$store.getters.currentCollective.key,
        listingType: 0,
        searchString: searchString || '',
        typeKey: null,
        page,
        perPage: 16,
      });
    },
    async handleCreateItem() {
      if (!this.formInput.name) {
        return;
      }
      try {
        const requestConfig = { name: {}, description: {}, headline: {} };
        const locale = this.$store.state.locale.currentLocale;
        requestConfig.name[locale] = this.formInput.name;
        requestConfig.typeKey = this.selectedType?.key;
        requestConfig.link = this.formInput.link;
        requestConfig.description[locale] = this.formInput.description;
        requestConfig.headline[locale] = this.formInput.headline;
        requestConfig.organizationKey = this.formInput.organization?.key;

        await this.$store.dispatch('createItem', {
          item: {
            itemType: 'entities',
            requestConfig,
            storedKey: this.key,
            forceAPICall: true,
            customName: 'entitiesByType',
          },
          file: this.itemImage,
        });
        this.formInput = {};
        // this.addDefaultType();
        this.itemImage = null;
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('entities.messages.success-create'),
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        });
        this.fetchEntities(true);
      } catch (error) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('entities.messages.error-create'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    searchItems(value) {
      this.searchInput = value;
      const searchTerm = value.toLowerCase().trim();
      if (!searchTerm) {
        this.results = false;
        return;
      }
      this.results = this.entitiesData.filter((item) => Object.values(item.name)[0].toLowerCase().includes(searchTerm));
    },
    async handleRemoved(key) {
      await this.fetchEntities();
    },
    openModal() {
      if (this.types.length > 0) {
        const index = this.types.findIndex((item) => item.key === this.type.key);
        if (index >= 0) {
          this.selectedType = this.types[index];
        } else this.selectedType = this.types[0];
      }
      return {};
    },
  },
};
</script>
